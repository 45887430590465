export const BFFProfileAdminSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Profile Admin BFF API",
      "description": "API for BFF Admin Profile",
      "version": "1.1.0"
    },
    "paths": {
        "/api/v1/stores": {
          "get": {
            "tags": ["Stores"],
            "summary": "Get list of all stores (with pagination)",
            "description": "Fetch a paginated list of stores along with their details.",
            "parameters": [
              {
                "in": "query",
                "name": "page",
                "schema": {
                  "type": "integer",
                  "minimum": 1,
                  "default": 1
                },
                "description": "The page number to retrieve."
              },
              {
                "in": "query",
                "name": "pageSize",
                "schema": {
                  "type": "integer",
                  "minimum": 1,
                  "default": 10
                },
                "description": "The number of items per page."
              },
              {
                "in": "query",
                "name": "search",
                "schema": {
                  "type": "string"
                },
                "description": "Full-text search, for instance on storeName."
              },
              {
                "in": "query",
                "name": "storeId",
                "schema": {
                  "type": "string"
                },
                "description": "Filter by store ID."
              },
              {
                "in": "query",
                "name": "customerId",
                "schema": {
                  "type": "string"
                },
                "description": "Filter by customer ID."
              },
              {
                "in": "query",
                "name": "activeStatus",
                "schema": {
                  "type": "string"
                },
                "description": "Filter by store status."
              },
              {
                "in": "query",
                "name": "points",
                "schema": {
                  "type": "string"
                },
                "description": "Filter stores by points."
              },
              {
                "in": "query",
                "name": "deliveryDays",
                "schema": {
                  "type": "string"
                },
                "description": "Filter stores by delivery days. Supports comma-separated values (e.g., 'Monday,Tuesday')."
              },
              {
                "in": "query",
                "name": "cutoffTime",
                "schema": {
                  "type": "string"
                },
                "description": "Filter stores by cutoff time."
              }
            ],
            "responses": {
              "200": {
                "description": "Paginated list of stores.",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "items": {
                          "type": "array",
                          "items": {
                            "type": "object",
                            "properties": {
                              "storeId": {
                                "type": "string",
                                "example": "0100000102"
                              },
                              "storeName": {
                                "type": "string",
                                "example": "Main Street Store"
                              },
                              "customerId": {
                                "type": "string",
                                "example": "0100000102"
                              },
                              "activeStatus": {
                                "type": "string",
                                "example": "Active"
                              },
                              "points": {
                                "type": "integer",
                                "example": 250
                              },
                              "deliveryDays": {
                                "type": "array",
                                "items": {
                                  "type": "string"
                                },
                                "example": ["Monday", "Tuesday"]
                              },
                              "cutoffTime": {
                                "type": "string",
                                "example": "10:00"
                              }
                            }
                          },
                          "description": "The list of stores for the current page."
                        },
                        "page": {
                          "type": "integer",
                          "description": "The current page number.",
                          "example": 1
                        },
                        "pageSize": {
                          "type": "integer",
                          "description": "The number of items returned per page.",
                          "example": 10
                        },
                        "totalItems": {
                          "type": "integer",
                          "description": "The total number of items available.",
                          "example": 25
                        },
                        "totalPages": {
                          "type": "integer",
                          "description": "The total number of pages available.",
                          "example": 3
                        }
                      }
                    }
                  }
                }
              },
              "500": {
                "description": "Internal server error.",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "error": {
                          "type": "string",
                          "description": "Error message describing the server issue."
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "/api/v1/customers": {
            "get": {
                "tags": ["Customers"],
                "summary": "Get list of all customers (with pagination and filtering)",
                "description": "Fetch a paginated list of customers with optional filtering based on name, email, phone, number of stores, Okta status, IDPID, and SAPID.",
                "parameters": [
                    {
                        "in": "query",
                        "name": "page",
                        "schema": {
                            "type": "integer",
                            "minimum": 1,
                            "default": 1
                        },
                        "description": "The page number to retrieve."
                    },
                    {
                        "in": "query",
                        "name": "pageSize",
                        "schema": {
                            "type": "integer",
                            "minimum": 1,
                            "default": 10
                        },
                        "description": "The number of items per page."
                    },
                    {
                        "in": "query",
                        "name": "search",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Full-text search across multiple fields (e.g., name, email, etc.)."
                    },
                    {
                        "in": "query",
                        "name": "name",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by customer name."
                    },
                    {
                        "in": "query",
                        "name": "email",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by customer email."
                    },
                    {
                        "in": "query",
                        "name": "phone",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by customer phone number."
                    },
                    {
                        "in": "query",
                        "name": "numberOfStores",
                        "schema": {
                            "type": "integer"
                        },
                        "description": "Filter by number of stores."
                    },
                    {
                        "in": "query",
                        "name": "oktaStatus",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by Okta status (e.g., 'ACTIVE' or 'INACTIVE')."
                    },
                    {
                        "in": "query",
                        "name": "idpId",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by IDPID."
                    },
                    {
                        "in": "query",
                        "name": "sapId",
                        "schema": {
                            "type": "string"
                        },
                        "description": "Filter by SAPID."
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Paginated list of customers.",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "items": {
                                            "type": "array",
                                            "description": "List of customer records.",
                                            "items": {
                                                "type": "object",
                                                "properties": {
                                                    "id": {
                                                        "type": "string",
                                                        "example": "5202398"
                                                    },
                                                    "name": {
                                                        "type": "string",
                                                        "example": "John Smith"
                                                    },
                                                    "email": {
                                                        "type": "string",
                                                        "example": "john@example.com"
                                                    },
                                                    "phone": {
                                                        "type": "string",
                                                        "example": "555-123-4567"
                                                    },
                                                    "numberOfStores": {
                                                        "type": "integer",
                                                        "example": 3
                                                    },
                                                    "oktaStatus": {
                                                        "type": "string",
                                                        "example": "ACTIVE"
                                                    },
                                                    "idpId": {
                                                        "type": "string",
                                                        "example": "00u27ca3qsar1Mivz0h8"
                                                    },
                                                    "sapId": {
                                                        "type": "string",
                                                        "example": "0100000102"
                                                    }
                                                }
                                            }
                                        },
                                        "page": {
                                            "type": "integer",
                                            "description": "The current page number.",
                                            "example": 1
                                        },
                                        "pageSize": {
                                            "type": "integer",
                                            "description": "The number of items returned per page.",
                                            "example": 10
                                        },
                                        "totalItems": {
                                            "type": "integer",
                                            "description": "The total number of items available.",
                                            "example": 42
                                        },
                                        "totalPages": {
                                            "type": "integer",
                                            "description": "The total number of pages available.",
                                            "example": 5
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal server error.",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "error": {
                                            "type": "string",
                                            "description": "Error message describing the server issue."
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
  }