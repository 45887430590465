import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import "./CSS/login.css";
import Logo from "../assets/Logo_White.png";
import LogoSign from "../assets/CEPLogo.png"
import { Link } from "react-router-dom";

function Login() {
  const { oktaAuth } = useOktaAuth();

  const handleSubmit = () => {
    oktaAuth.signInWithRedirect({});
  };

  return (
    <>
      <div className="background">
        <div className="container_content">
                <div className="logoImg">
                    <img src={Logo} alt="Image not loaded" />
                </div>
                <div className="content">
                    <div className="WelcomeMsg">
                        <h1>Welcome to</h1>
                    </div>
                    <div className="CEP">
                        <p id="CEP-text">CEP Developer Portal</p>
                    </div>
                    <div className="LogoSign">
                        <img src={LogoSign} alt="Error" />
                    </div>
                    <div className="sign-btn">
                        <button
                            type="submit"
                            onClick={handleSubmit}
                        >
                            SIGN IN
                        </button>
                    </div>

                </div>
        </div>
      </div>
    </>
  );
}

export default Login;
