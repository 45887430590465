import React from "react";
import './CSS/login.css'
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import { IconApps } from '@tabler/icons';
import Footer from "./Footer/Footer";

function Swagger() {
  return (
    <>

      <Header />

      <div className="main-container">
        <form className="form-card">
          <div className="row text-left">
            <div className="form-group col-sm-2 flex-column d-flex">
              <Sidebar />
            </div>
            <div className="form-group col-sm-10 flex-column d-flex">
              <div className="container-custom" style={{ marginRight: '25px', marginTop: '20px' }}>
                <div className="form-group col-sm-4 flex-column d-flex ">
                  
                    <p  className="PageHeader">
                      List of Applications
                    </p>
                 
                </div>
                <div className="row main-app-card d-flex">
                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/uhokta'>
                      <div className='d-flex flex-row' style={{marginTop:'2px'}}>
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> Unified Okta </h5>
                        {/* <p className='app-description'>Dummy text dummy text dummy text</p> */}
                        </div>
                        
                      </div>

                    </Link>
                  </div>

                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/B2B'>
                      <div className='d-flex flex-row' style={{marginTop:'2px'}}>
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> B2B </h5>
                    
                        </div>
                        
                      </div>

                    </Link>
                  </div>

                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/B2C'>
                      <div className='d-flex flex-row' style={{marginTop:'2px'}}>
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> B2C </h5>
                        
                        </div>
                        
                      </div>

                    </Link>
                  </div>
                  </div>

                <div className="row justify-content-between text-center">
                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/loyalty'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> Loyality </h5>
                        
                        </div>    
                      </div>
                    </Link>
                  </div>

                   <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/KSA'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> KSA </h5>
                        
                        </div>    
                      </div>
                    </Link>
                  </div>

                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/HR'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                        <h5 className='app-name'> HR Gamification </h5>
                        
                        </div>    
                      </div>
                    </Link>
                  </div>

                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/BDR'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                          <h5 className='app-name'> BDR Tool </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/B2B-Rewards'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                          <h5 className='app-name'> B2B Rewards Service </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/BFF-Admin'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                          <h5 className='app-name'> BFF Admin </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                      <Link to='/swagger/b2b/apex'>
                          <div className='d-flex flex-row' style={{marginTop:'2px'}}>
                              <IconApps className='app-icon'/>
                              <div className='app-name-desc'>
                                  <h5 className='app-name'>B2B for APEX</h5>
                                  {/* <p className='app-description'>Dummy text dummy text dummy text</p> */}
                              </div>

                          </div>

                      </Link>
                  </div>

                  <div className="form-group col-sm-3 flex-column d-flex new-card" style={{padding:'0'}}>
                    <Link to='/swagger/BFF-Profile-Admin'>
                      <div className='d-flex flex-row' >
                        <IconApps className='app-icon'/>
                        <div className='app-name-desc'>
                          <h5 className='app-name'>BFF Profile Admin</h5>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </form>

      </div>
      <footer className="FooterClass1">
        <Footer />
      </footer>
    </>
  );
}

export default Swagger;
